.acc-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 20px 40px;
    z-index: 1;
}
.upper {
    text-transform: uppercase;
    padding-right: 5px;
}