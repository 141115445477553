
.header {
    position: relative;
    z-index: 999;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
}
.header > * {
    pointer-events: all;
}

.header__title {
    font-size: 28px;
    color: #fff;
    text-align: center;
    margin-top: 16px;
    text-transform: uppercase;
    letter-spacing: 4.2px;
    margin-bottom: 80px;
}

.header__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 124px);
}
.header__nav-item {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
}

.header__nav-item:not(:last-child) {
    margin-bottom: 40px;
}

.header__nav-item {
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
}
.header__nav-link {
    align-items: center;
}
.header__nav-link svg {
    margin-right: 35px;
}

.header__nav-home::before {
    background-image: url("../../assets/imgs/home.svg");
}

.header__nav-donate {
    padding: 14px 40px;
    border: 1px solid #fff;
    text-align: center;
}

.header__nav-copy {
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding-bottom: 20px;
}
.header__right {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }
