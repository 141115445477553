.account {
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(../../assets/imgs/bg.jpg) no-repeat center / cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.account-content {
    flex-grow: 1;
    max-width: 520px;
    width: 100%;
}
.account-content  .page-title {
    font-Size: 36px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: normal;
    color: #fff;
}
.account-header {
    position: relative;
    width: 100%;
    padding-top: 20px;
}
.account-title {
    font-size: 80px;
    font-weight: 900;
    letter-spacing: 5.3px;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    text-transform: uppercase;
    text-align: center;
}
.account-about {
    position: absolute;
    right: 30px;
    top: 35px;
    color: #fff;
    padding: 20px 0;
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    margin-top: 10px;
}
.account-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 640px;
}
.account-subtitle {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: center;
    margin-bottom: 35px;
}
.account-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    width: 100%;
    margin-bottom: 30px;
}
.account-input {
    display: block;
    width: 100%;
    height: 52px;
    background-color: rgba(255, 255, 255, .4);
    border: 1px solid #fff;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 30px;
    padding: 5px 0px;
    color: #fff;
    text-align: center;
}

.account-input::placeholder {
    color: white;
}
.account-input:focus::placeholder {
    opacity: 0;
}
.date-picker::placeholder {
    color: white;
}
.date-picker:focus::placeholder {
    opacity: 0;
}
.account-input .select {
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: transparent;
    border: none;
    font: inherit;
    text-align: center;
}
.account-input .select:focus {
    outline: none;
    background-color: transparent;
}
.account-input .select option {
    color: var(--main-color);
}

.account-input::placeholder {
    text-align: center;
    color: inherit;
}
.account-submit {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #f3bfda;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
}
.account-submit:active {
    background-color: var(--main-color);
}
.reg {
    text-transform: uppercase;
    color: #fff;
    font-size: 18px;
    line-height: 1;
}
.reg:hover {
    text-decoration: underline;
}
.account-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 60px 20px 30px;
}
.account-socials {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}
.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}
.social-link svg {
    stroke: #fff;
    stroke-width: 1.5px;
}
.account-powered {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    color: #fff;
    transform-origin: center left;
    transform: rotate(-90deg);
}
.input-wrapper {
    position: relative;
    width: 100%;
}

.error-btn {
    position: absolute;
    top: 33%;
    transform: translateY(-50%);
    right: 20px;
    display: none;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: url('../../assets/imgs/error.svg') no-repeat center / contain;
}


.user-input .error-btn {
    right: 5px;
    width: 25px;
    height: 25px;
}

.input-wrapper.error .account-input {
    border-color: #f26363;
}
.input-wrapper.error .error-btn {
    display: block;
}
.user-input.error .error-btn {
    display: block;
}
.error-btn:hover + .error-msg,
.error-btn:focus + .error-msg {
    display: block;
}
.account-input.error .account-input {
    border-color: #f26363;
}
/* Customize the label (the container) */
.container-terms-policy {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-terms-policy input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
w
.input_pass_container {
    position: relative;
}

.password_icon_container {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 40%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}

.profile_password_icon_container {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}


/* Create a custom checkbox */
.container-terms-policy__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    transition: .2s;
}

/* On mouse-over, add a grey background color */
.container-terms-policy:hover input ~ .container-terms-policy__checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-terms-policy input[data-active="true"] ~ .container-terms-policy__checkmark {
    background-color: #fff;
}
.container-terms-policy .container-terms-policy__checkmark[data-error="true"]  {
    background-color: #f26363!important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-terms-policy__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    transition: .2s;
}

/* Show the checkmark when checked */
.container-terms-policy input[data-active="true"] ~ .container-terms-policy__checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-terms-policy .container-terms-policy__checkmark:after {
    left: 10px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #baacaa;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.terms-police-title{
    text-transform: capitalize;
    color: #fff;
    font-size: 18px;
    line-height: 1;
    text-decoration: underline;
    cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
@media (max-width: 768px) {
    .account-content {
        padding: 0 20px
    }
    .account-footer {
        padding: 40px 60px 20px 10px;
    }
    .account-title {
        font-size: 30px;
        top: 100px;
        position: relative;
    }
    .popup-title {
        font-size: 30px;
    }
    .account-subtitle {
        font-size: 20px;
        padding-top: 60px;
    }
    .error-msg {
        right: 65px !important;
    }
    .account-dashboards {
        position: relative !important;
        display: contents !Important;
    }
    .user-left {
        flex-grow: unset !important;
        flex-shrink: unset !important;
        width: 90% !important;
        margin-top: 80px;
        margin-bottom: 20px;
        height: auto !important;
    }
    .user-right {
        flex-grow: unset !important;
        flex-shrink: unset !important;
        width: 90% !important;
        margin-bottom: 80px;
        height: auto !important;
    }
    .user-input {
        width: auto !important;
    }
    .user-pass .user-detail {
        display: block !important;
    }
}
