.account-dashboards {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(216, 216, 216, .4);
    padding: 61px 140px;
    overflow: auto;
    display: flex;
    align-items: stretch;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .account {
        /*transform: scale(0.8);*/
        zoom: 80%
    }
}
.top-btns {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: stretch;
    gap: 1px;
}
