.gallery-section{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
}
.gallery {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background-color: rgba(44, 71, 108, .7);*/
    background-color: RGB(27, 40, 58, .9);
    z-index: 4;
    display: none;
}

.gallery.active {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 999;
    gap: 20px;
    padding: 70px 0px;
}
.gallery-content {
    /*position: relative;*/
    /*max-width: 80%;*/
    /*max-height: 85vh;*/
    /*position: relative;*/
    /*max-width: 90%;*/
    max-height: 100vh;
    display: grid;
    grid-template-columns: 68vw 22vw;
    grid-template-areas:
        "m c"
        "i c";
    grid-gap: 10px;
}
.gallery.tili .splide-content{
    display: grid;
    max-width: 90%;
    max-height: 90vh;
    grid-template-areas:"m"
}
.gallery-main {
    position: relative;
    grid-area: m;
}
/*.gallery-main:hover {*/
/*    cursor: url('../../assets/imgs/gall-zoomin.svg'), auto;*/
/*}*/
/*.gallery-main.active:hover {*/
/*    cursor: url('../../assets/imgs/gall-zoomout.svg'), auto;*/
/*}*/
.gallery-items{
    grid-area: i;
}
.gallery-items .splide__list{
    display: flex;
    gap: 10px;
    /*width: 185px;*/
}
.gallery-item {
    display: block;
    overflow: hidden;
    background-color: #2C476C;
}
.gallery-item img {
    /*width: auto;*/
    height: 100%;
    object-fit: contain;
    /*margin: 0 auto;*/
}
.gallery-item video {
    width: 100%;
    height: 100%;
}
.single-gallery-video-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}
.splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
    border: 3px solid #EB572C;
}

.gallery-header{
    width: 100%;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    display: grid;
    justify-content: flex-end;
    grid-template-columns: 1fr 50px 50px 50px;
    grid-gap: 10px;
    align-items: center;
}
.tili .gallery-header{
    grid-template-columns: 1fr 50px;
}
.full-screen-header{
    grid-template-columns:  50px 50px;
}

.gallery-header-title{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "NotoSansArmenian-Bold";
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    padding: 0px 5vw;;
}
.tili .gallery-header-title{
    text-align: center;
}
.gallery-btn {
    top: 0;
    right: 0;
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0000;
    z-index: 1;
}
.gallery-close,.gallery-maximize,.gallery-tili {
    position: relative;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
}
.gallery-close svg,.gallery-maximize svg,.gallery-tili svg {
    width: 100%;
    height: 100%;
    /*transform: rotate(45deg);*/
}
.splide__arrows{
    justify-content: center;
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}
.prev-main {
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(180deg);
}
.next-main {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.gallery-zoom {
    top: 100px;
    right: 200px;
    border-radius: 50%;
    overflow: hidden;
}

.splide--nav>.splide__track>.splide__list>.splide__slide {
    border: 0px solid transparent;
    cursor: pointer;
}
.splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
    border-color: #EB572C !important;
}
.gallery-title {
    background-color: rgb(255 255 255 / 80%);
    font-size: 11px;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in;
}
.gallery-caption {
    background-color: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.1s ease-in;
    overflow: hidden auto;
}
.gallery-caption::-webkit-scrollbar {
    width: 10px;
}

.gallery-caption::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 4px;
    border: 10px solid rgba(32, 33, 36, 0.6);
}
.gallery-caption p{
    opacity: 1;
    color: rgba(44,71,108,1);
    font-family: "NotoSansArmenian-Regular";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    text-align: left;
    line-height: 20px;
    padding: 30px;
}
.splide--nav>.splide__track>.splide__list>.splide__slide .gallery-title {
    opacity: 1;
    color: rgba(44, 71, 108, 1);
    font-family: "NotoSansArmenian-Regular";
    font-size: 33.8px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 46.8px;
}
.gallery-block {
height: 65%
}
.splide.gallery-main .splide__list .gallery-title {
    position: absolute;
    bottom: 0;
    height: 1px;
    transition: .3s;
    opacity: 0;
    overflow: hidden auto;
}
.splide.gallery-main .splide__list .gallery-block {
    position: relative;
    height: 100%;
}
.gallery-block:hover .gallery-title {
    position: absolute;
    bottom: 0;
    opacity: 1!important;
    visibility: visible;
    transition: all 0.1s ease-in;
    display: block!important;
    background-color: RGB(44, 71, 108,80%);
    max-height: 100%;
    padding: 20px;
    height: 100%!important;
    z-index: 5;

}
.gallery-block .gallery-title::-webkit-scrollbar {
    width: 8px;
}
.gallery-block .gallery-title::-webkit-scrollbar-track {
    background-color: #e9e9e9;
}
.gallery-block .gallery-title::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 4px;
}

.gallery-title p{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "NotoSansArmenian-Medium";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 20px;
}
.gallery-text-content{
    width: 100%;
    max-height: 100%;
    background-color: white;
    grid-area: c;
}
.full-screen{
    width: 80%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.gallery-content-full-screen  .full-screen img{
    object-fit: contain;
}
.gallery-content-full-screen .full-screen video{
    width: 100%;
    height: 100%;
}
.gallery-section[data-full-screen="true"] .full-screen-gallery-item{
    display: grid;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: RGB(27, 40, 58);
    z-index: 999;
}
.gallery-section[data-full-screen="false"] .full-screen-gallery-item{
    display: none;
}
.tili-item{
    display: grid;
    /*grid-template-columns: repeat(auto-fill,minmax(10vw,28vw));*/
    grid-template-columns: repeat(4,1fr);
    grid-auto-columns: max-content;
    /*grid-template-rows: repeat(3,30%);*/
    /*grid-template-rows: repeat(3,1fr);*/
    grid-template-rows: repeat(3,minmax(33%,max-content));
    grid-gap: 5px;
/* overflow: hidden; */
    height: 100%;
    justify-content: center;
    align-content: center;
}

.single-gallery>div{
    position: relative;
    width: 100%;
    height: 100%;
}
.single-gallery-iframe:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.tili-iframe{
position: relative;
}
.tili-iframe-item:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.tili-item *{
    width: 100%;
    height: 100%;
}
[data-tili="true"]{
    display: flex!important;
}
[data-tili="false"]{
    display: none!important;
}
.hiddConroll::-webkit-media-controls {
    display: none;
}

/* Could Use thise as well for Individual Controls */
.hiddConroll video::-webkit-media-controls-play-button {}

.hiddConroll video::-webkit-media-controls-volume-slider {}

.hiddConroll video::-webkit-media-controls-mute-button {}

.hiddConroll video::-webkit-media-controls-timeline {}

.hiddConroll video::-webkit-media-controls-current-time-display {}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .gallery.active{
        /*zoom: 143%;*/
    }
    .gallery-section{
        zoom: 143%;
    }
}