.user-left {
  flex-grow: 1;
  flex-shrink: 0;
  /* max-width: 540px; */
  width: 28%;
  height: 100%;
  background-color: #fff;
  /* min-height: 750px; */
}
.user-right {
  lex-grow: 1;
  flex-shrink: 0;
  /*max-width: 993px;*/
  width: 70%;
  height: 100%;
  background-color: #fff;
  /*min-height: 750px;*/
}
.user-photo {
  position: relative;
  width: 100%;
  max-height: 367px;
  aspect-ratio: 1.49;
  background: url("../../assets/imgs/user-pic-placeholder.jpg") no-repeat center /
    cover;
}
.user-photo.uploaded {
  background: none;
}
.user-info {
  font-size: 20px;
  font-weight: bold;
  padding: 5px 40px;
  border-bottom: 1px solid #ccc;
}
.user-description {
  padding: 25px 30px;
}
.user-name {
  font-size: 24px;
  font-weight: 500;
}
.user-role {
  font-size: 18px;
  margin-bottom: 50px;
}
.user-contacts {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}
.user-contacts + .user-contacts {
  margin-top: 15px;
}
.user-right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 5px 40px;
}
.user-additional {
  font-size: 20px;
  font-weight: bold;
}
.user-header-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-edit-btn {
  width: 140px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
}
.user-edit-btn.link {
  background-color: #f6f6f6;
  border: 1px solid #e2e2e2;
}
.user-edit-btn.save {
  background-color: #6ebf46;
  color: #fff;
}
.user-additional-content {
  padding: 20px 40px 0;
}
.user-detail {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-input {
  position: relative;
  width: 640px;
  height: 100%;
  border: 1px solid #707070;
  border-radius: 18px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.user-input-pass {
  width: 240px !important;
}

.user-input.select {
  position: relative;
  width: 640px;
  height: 100%;
  border: none;
  border-radius: 18px;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.user-input .react-datepicker__input-container input {
  width: 100%;
  text-align: left;
  height: 30px;
}
.user-input.select > .select {
  width: 100%;
  height: 100%;
}

.user-input.border-none {
  border: none;
}
.user-input > svg {
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.user-input select {
  border: none;
  display: block;
  width: 100%;
  height: 100%;
}
.user-input select:focus {
  outline: none;
}
.user-form-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 30px;
}
.user-form-btn {
  width: 126px;
  height: 32px;
  border-radius: 15px;
  font-size: 16px;
  background-color: #f2f2f2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-form-btn.save {
  background-color: #f3bfda;
}
.user-pass {
  padding: 20px 40px;
  width: 993px;
}
.user-pass-text {
  font-size: 22px;
  font-weight: bold;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 40px;
  padding-bottom: 10px;
}
.user-pass-btn {
  font-size: 18px;
  width: 300px;
  height: 46px;
  border-radius: 23px;
  background-color: #f3bfda;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-pass form {
  width: 55%;
}
.account-info .user-input,
.user-pass .user-input {
  width: unset;
  margin-left: unset;
}
.user-pass input {
  width: 100%;
  height: 100%;
}
.user-pass input:focus {
  outline: none;
}
.account-info-content {
  padding: 15px 40px;
}

.account-info span {
  font-size: 16px;
  color: var(--main-color);
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.account-info input {
  width: 100%;
  height: 100%;
}
.account-info input:focus {
  outline: none;
}
.account-info-edit .user-input {
  height: 34px;
}
.photo-upload-label {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.photo-remove-label {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.photo-upload-label input {
  position: absolute;
  opacity: 0;
  width: 60px;
  height: 60px;
  z-index: -1;
}
.photo-upload-label svg {
  width: 30px;
  height: 30px;
}
