.header__burger {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 60px;
    height: 100%;
    font-size: 28px;
    z-index: 2;
    height: 60px;
}


.header__burger.active {
    left: 325px;
    background-color: var(--main-color);
    font-size: 0;
}
.header__burger.active::before,
.header__burger.active::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 2px;
    background-color: #fff;
}
.header__burger.active::before {
    transform: translate(-50%, -50%) rotate(45deg);
}
.header__burger.active::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
.header__nav {
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 325px;
    background-color: var(--main-color);
    padding: 0 20px;
}

.header__nav.active {
    left: 0;
}
