
.timeline {
    position: fixed;
    bottom: 0;
    z-index: 99;
    height: 84px;
    background-color: #fff;
    /*display: -ms-grid;*/
    /*display: grid;*/
    /*grid-template-columns: 48px 1fr 48px;*/
    align-items: end;
    width: 100%;
    z-index: 99;
    /*padding: 0 30px*/
}

.timeline__content.disable-timeline,
.lesson-back.disable-timeline,
.timeline__arrow.disable-timeline {
    opacity: 0.5 !important;
    pointer-events: none
}


.timeline__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    position: absolute;
}

.timeline__arrow.timeline__lesson_prev,
.timeline__arrow.timeline__lesson_next {
    bottom: 195px;
    z-index: 999;
    filter: invert(1);
}
.timeline__lesson_prev svg {
    transform: rotate(180deg);
}

/*.timeline.expand .timeline__arrow{*/
/*    bottom: 30px;*/
/*}*/

.timeline__prev svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.timeline__timeline {
    height: 39px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #000;
}

.timeline__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
}

.timeline__content-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: .5fr;
    grid-template-rows: .5fr;
    overflow-y: hidden;
}
.vis-panel.vis-right.vis-vertical-scroll{
    overflow-y: hidden!important;
}
.timeline__content-content.lesson-opened .timeline__content-list {
    display: none;
}

.timeline__content-list {
    height: 40px;
    padding: 0 32px;
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto;
}

.timeline__content-item {
    position: relative;
    font-size: 18px;
    display: inline-block;
}

.timeline__content-item:not(:last-child) {
    margin-right: 4px;
}

.timeline__content-item.one .timeline__content-btn {
    background: transparent -webkit-gradient(linear, left top, left bottom, from(#007BC2), to(#265AB4)) 0% 0% no-repeat;
    background: transparent linear-gradient(180deg, #007BC2 0%, #265AB4 100%) 0% 0% no-repeat;
}

.timeline__content-item.two .timeline__content-btn {
    background: transparent -webkit-gradient(linear, left top, left bottom, from(#6EAC4B), to(#167000)) 0% 0% no-repeat;
    background: transparent linear-gradient(180deg, #6EAC4B 0%, #167000 100%) 0% 0% no-repeat;
}

.timeline__content-item.three .timeline__content-btn {
    background: transparent -webkit-gradient(linear, left top, left bottom, from(#FFC400), to(#8E5E00)) no-repeat;
    background: transparent linear-gradient(180deg, #FFC400 0%, #8E5E00 100%) no-repeat;
}

.timeline__content-item.four .timeline__content-btn {
    background: transparent -webkit-gradient(linear, left top, left bottom, from(#FF4759), to(#AB0000)) no-repeat;
    background: transparent linear-gradient(180deg, #FF4759 0%, #AB0000 100%) no-repeat;
}

.timeline__content-item.five .timeline__content-btn {
    background: transparent -webkit-gradient(linear, left top, left bottom, from(#E161DD), to(#562454)) no-repeat;
    background: transparent linear-gradient(180deg, #E161DD 0%, #562454 100%) no-repeat;
}

.timeline__content-sub {
    position: absolute;
    margin-left: 40px;
}

.timeline__content-subbtn {
    display: block;
    padding: 5px 10px;
    margin-bottom: 4px;
    border-radius: 4px;
    background-color: rgba(32, 33, 36, 0.6);
}

.timeline__content-subbtn:nth-child(2) {
    margin-left: 40px;
}

.timeline__content-subbtn:nth-child(3) {
    margin-left: 80px;
}

.timeline__content-subbtn:nth-child(4) {
    margin-left: 120px;
}

.timeline__content-btn {
    padding: 6px 20px;
    white-space: nowrap;
    border-radius: 0 0 20px 20px;
    margin-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.timeline__btns {
    position: absolute;
    right: 0;
    top: -61px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.timeline__btn {
    display: block;
    width: 60px;
    height: 60px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.timeline__btn + .timeline__btn {
    margin-left: 1px;
}

.timeline__minimize {
    display: none;
}

.timeline.expand {
    height: 230px;
}

.timeline.expand .timeline__maximize {
    display: none;
}

.timeline.expand .timeline__minimize {
    display: block;
}

.timeline.expand .timeline__content-item {
    height: 100%;
}

.timeline.expand .timeline__content-list {
    height: unset;
}

.timeline.expand .timeline__content-content {
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
}

.timeline.expand .lesson__content {
    display: -ms-grid;
    display: grid;
}

.timeline .lesson {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
    max-height: 180px;
    top: -5px;
    background: #f3f3f3;
}
.timeline .lesson .vis-timeline {
    z-index: 99;
}
.vis-timeline {
    display: flex;
    flex-direction: column;
    overflow: visible !important;
    border: none !important;
}
.vis-background {
    overflow: visible !important;
}
/*.vis-panel.vis-center, .vis-itemset {*/
/*    height: calc(230px - 40px) !important;*/
/*}*/
.vis-time-axis .vis-grid.vis-minor,
.vis-time-axis .vis-grid.vis-major {
    border-color: transparent !important;
}
.vis-time-axis .vis-text.vis-minor {
    color: #375276 !important;
    font-size: 13px;
    border-left: 1px solid #375276 ;
    height: 10px;
    overflow: visible;
    top: -5px;
    /*letter-spacing: -1px;*/
}
.vis-time-axis .vis-text {
    white-space: normal !Important;
    line-height: 13px;
    padding-top: 7px !important;
}
/*.vis-time-axis .vis-text.vis-minor {*/
/*    color: #375276 !important;*/
/*    font-size: 14px;*/
/*    max-width: 50px;*/
/*    line-height: 1;*/
/*    white-space: pre-wrap;*/
/*    border-left: 1px solid #375276;*/
/*    height: 10px;*/
/*    overflow: visible;*/
/*    top: -5px;*/
/*    padding-top: 5px;*/
/*}*/
.vis-time-axis .vis-text.vis-major {
    color: white !important;
    margin-top: 10px;
    font-size: 15px;
}
.vis-item {
    display: block;
    padding-bottom: 5px;
    margin-bottom: 0 ;
    border-radius: 4px !important;
    background-color: transparent !important;
    color: white !important;
    border: none !important;
    overflow: hidden;
}

.vis-item:not(.isGroup) {
    display: block;
    margin-bottom: 0 ;
    background-color: transparent !important;
    color: white !important;
    padding-bottom: 0px;
    border-radius: 17px !important;
    border: 1px solid var(--main-color) !important;
    border-color: var(--main-color) !important;
}

.vis-item .item-content {
    font-size: 13px;
    display: block;
    padding: 5px 17px 5px 5px;
    width: 100%;
    color: var(--main-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.vis-item .item-content.active {
    background-color: #95d4f3;
    border-color: #95d4f3;
    font-weight: bold;
}

.vis-item .vis-item-content {
    padding: 0 !important;
}
.vis-panel.vis-bottom {
    height: 50px;
    bottom: 0;
    flex-shrink: 0;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid #375276 !important;
}
.vis-time-axis.vis-foreground {
    overflow: visible;
}
.vis-item.isGroup {
    top: 0 !important;
    position: sticky;
    padding: 0 !important;
    display: block;
}
.vis-item.isGroup .group-content {
    top: 0 !important;
    padding: 6px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
    background-color: var(--main-color);
    font-size: 13px;
    text-transform: uppercase;

}
.vis-item.isGroup .group-content.active {
    background-color: #95d4f3;
    color: var(--main-color);
    font-weight: bold;
}

.lesson__show  {
    position: absolute;
    width: 10%;
    top: 36px;
    right: 0;
    height: calc(230px - 51px - 36px);
    background-color: rgba(32, 33, 36, 0.6);
}
.vis-labelset .vis-label .vis-inner {
    padding: 0 !important;
    height: 100%
}
.vis-labelset .vis-label {
    /*border-bottom: 1px solid #747474 !important;*/
    border: none !important
}
.vis-foreground .vis-group {
    /*border-bottom: 1px solid #888888 !important;*/
    border: none !important;
    margin-top: -20px;
}
.vis-left.vis-panel.vis-vertical-scroll {
    display: none;
}
.vis-panel.vis-center {
    /*border-color: #888888 !important;*/
    border: none !important;
    left: 50%!important;
    transform: translateX(-50%)!important;
}
.timeline.expand .vis-panel.vis-center {
    /*top: 45px !important;*/
    overflow: hidden !important;
    /*overflow-y: scroll !important;*/
}

.vis-panel.vis-center::-webkit-scrollbar {
    width:20px;
}

/* Track */
.vis-panel.vis-center::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.vis-panel.vis-center::-webkit-scrollbar-thumb {
    background: #2c476c;
    padding: 0 4px;
    border-top: 7px solid transparent;
    border-bottom:7px solid transparent;
    border-right: 7px solid transparent;
    border-left:7px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
}

/* Handle on hover */
.vis-panel.vis-center::-webkit-scrollbar-thumb:hover {
    background: #2c476c;
    border-radius: 10px;
}

.vis-panel > .vis-content {
    transform: translateY(0px) !important;
}
.vis-panel .vis-shadow.vis-bottom {
    display: none;
}
.vis-left.vis-panel.vis-vertical-scroll {
    /*top: 45px !important;*/
}
.vis-group.isLesson .vis-item.isGroup {
    transform: translateY(0px);
    top: -45px !important;
    padding: 6px 20px !important;
    white-space: nowrap;
    border-radius: 0 0 20px 20px !important;
    margin-bottom: 10px;
    position: fixed !important;
    z-index: 2;
    height: 40px !important;
}
.isLesson .vis-item {
    /*top: 4px !important;*/
}
.vis-labelset .vis-label,
.vis-group.isLesson {
    /*height: 42px !important;*/
}
.vis-item.isGroup .vis-item-content,
.vis-item .vis-item-content {
   width: 100%;
}
.timeline__content.inner .vis-panel.vis-bottom {
    /*left: 0 !important;*/
    width: 100% !important;

}
.vis-panel.vis-bottom{
    left: 50%!important;
    transform: translateX(-50%)!important;
}
/*.lesson__content-cat {*/
/*    height: 100%;*/
/*}*/
.vis-custom-time {
    background-color: #5faaf8 !important;
    width: 0px!important;
    border: 1px solid #5faaf8
}

.vis-custom-time:active{
    background-color: #EB572C!important;
    border: 1px solid #EB572C
}

.vis-custom-time:active div::after{
    border-color: #EB572C!important
}
.timeline.lesson-timeline .vis-custom-time div::after {
    content: '';
    width: 15px;
    left: 3px;
    height: 15px;
    background: #ffffff;
    border: 1px solid;
    border-color: #5faaf8;
    position: absolute;
    bottom: 17px;
    border-radius: 20px;
}
.timeline.lesson-timeline .vis-custom-time:active div::after {
    border-color: #EB572C;
}

.timeline .vis-custom-time div::after {
    content: '';
    width: 15px;
    left: 3px;
    height: 15px;
    background: #ffffff;
    border: 1px solid;
    border-color: #5faaf8;
    position: absolute;
    bottom: 42px;
    border-radius: 20px;
}
.timeline .vis-custom-time div::after {
    border-color: #5faaf8;
}
.vis-label.vis-group-level-0.isGroup, .vis-group.isGroup { height: 50px !important;}

/*.vis-item.vis-range.isGroup.vis-readonly:has(div.active) {*/
/*    z-index: 222;*/
/*}*/
/*.vis-item.vis-range {*/
/*    z-index: 222;*/
/*}*/
