@font-face {
  font-family: 'Bokonique';
  src: url("./assets/fonts/Bokonique-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url("./assets/fonts/Mardoto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url("./assets/fonts/Mardoto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Bold.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Medium.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Light.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Regular.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Black.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
      url('./assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
      url('./assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-It.woff2') format('woff2'),
      url('./assets/fonts/AvenirNextLTPro-It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-Medium.woff2') format('woff2'),
      url('./assets/fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('./assets/fonts/LucidaGrande.woff2') format('woff2'),
      url('./assets/fonts/LucidaGrande.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('./assets/fonts/LucidaGrande-Bold.woff2') format('woff2'),
      url('./assets/fonts/LucidaGrande-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


:root {
  --main-color: #2c476c;
}
body {
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Noto Sans Armenian', sans-serif;
  color: var(--main-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

a, button, input {
  font: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

input {
  font: inherit;
  color: inherit;
}

ul {
  list-style: none;
}

img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

iframe {
  width: 100%;
  height: 100%;
}

svg {
  pointer-events: none;
}
svg.icon {
  strokeWidth: 1.5px;
  strokeDasharray: 0 0;
}
.with-svg {
  display: flex;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-right: 20px;
}
.with-svg svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}
#root {
  height: 100%;
}
