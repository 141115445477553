.terms-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    padding: 25px;
    width: 95%;
    height: 95%;
    z-index: 1;
    margin: auto;
    overflow-y: scroll;
}
.terms-popup.active {
    display: block;
}
.terms-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: left;
}
.terms-title {
    font-size: 24px;
    letter-spacing: 1.5px;
    color: var(--main-color);
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
}

.terms-text {
    font-size: 18px;
    color: var(--main-color);
    font-family: Lucida Grande;
    line-height: 1.4;
    letter-spacing: 2.25px;
    margin-bottom: 25px;
}

.terms-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 42px;
    height: 42px;
    transform: rotate(45deg);
}
.terms-police-title{

}