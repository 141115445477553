.error-alert {
    padding: 10px;
    color: #fff;
    display: flex;
    font-size: 14px;
    max-width: 400px;
    text-align: left;
    margin-bottom: 20px;
    background: #f26363;
    position: absolute;
    right: 20px;
    top: 20px;
}
